import React from "react";
import Helmet from 'react-helmet';
import Layout from '../components/layout';
import favicon from '../images/favicon.ico';
import { Fade } from 'react-reveal';

// components
import Navbar from '../components/navbar/navbar';
import Footer from '../components/footer/footer';
import ContactSection from "../components/contact/contact";

// icons
import icon_left from "../images/icons/icon-professionalitet.svg";
import icon_center from "../images/icons/icon-auktorisation.svg";
import icon_right from "../images/icons/icon-erfarenhet.svg";


function AuktoriseradeRedovisningskonsult() {
    return (
        <Layout>
            <Helmet>
                <title>Excendo Redovisning AB</title>
                <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
                <meta name="description" content="" />
                <meta name="keywords" content="" />
                <meta property="og:title" content="Excendo Redovisning AB" />
                <meta property="og:tyope" content="website" />
                <meta property="og:discription" content="" />
                <meta property="og:image" content=" " />
                <meta property="og:locale" content="sv_SE" />
                <meta property="og:url" content="http://www.excendo.se/" />
                <link rel="cannonical" href="http://www.excendo.se/" />
            </Helmet>

            <Navbar whitemode={true} />

            <section className="-mt-20">
                {/*-------------- hero section - START ---------------*/}
                <div className="w-screen bg-redovisningskonsult-hero bg-center bg-no-repeat bg-cover">
                    <div className="custome_overlay flex justify-center items-center py-48">
                        <h1 className="text-4xl font-semibold text-white text-center">Auktoriserade Redovisningskonsult</h1>
                    </div>
                </div>
                {/*-------------- hero section - END ---------------*/}
            </section>



            <section>
                {/*-------------- card section - START ---------------*/}
                <div className="container mx-auto -mt-12 px-4 xl:px-24">
                    <Fade top cascade>
                        <div className="w-full flex flex-col md:flex-row justify-center lg:justify-between items-center" >
                            <div className="w-full md:w-4/12 pb-4 md:pr-4">
                                <div className="w-full flex flex-col justify-center items-center bg-gray-50 md:bg-white rounded-lg py-4 md:py-8 shadow-md md:shadow-lg">
                                    <img className="object-center object-cover h-20 w-20" src={icon_left} alt="Professionalitet" />
                                    <h2 className="text-lg mt-2 font-medium text-black-excendo">Professionalitet</h2>
                                </div>
                            </div>
                            <div className="w-full md:w-4/12 pb-4 md:px-4">
                                <div className="w-full flex flex-col justify-center items-center bg-gray-50 md:bg-white rounded-lg py-4 md:py-8 shadow-md md:shadow-lg">
                                    <img className="object-center object-cover h-20 w-20 mx-auto" src={icon_center} alt="Auktorisation" />
                                    <h2 className="text-lg mt-2 font-medium text-black-excendo">Auktorisation</h2>
                                </div>
                            </div>
                            <div className="w-full md:w-4/12 pb-4 md:pl-4">
                                <div className="w-full flex flex-col justify-center items-center bg-gray-50 md:bg-white rounded-lg py-4 md:py-8 shadow-md md:shadow-lg">
                                    <img className="object-center object-cover h-20 w-20 mx-auto" src={icon_right} alt="Erfarenhet" />
                                    <h2 className="text-lg mt-2 font-medium text-black-excendo">Erfarenhet</h2>
                                </div>
                            </div>
                        </div>
                    </Fade>
                </div>
                {/*-------------- card section - END ---------------*/}
            </section>



            <section>
                {/*-------------- text section - START ---------------*/}
                <div className="container mx-auto px-4 xl:px-24 my-24">
                    <div className="flex flex-col lg:flex-row items-start justify-start">
                        <div className="w-full flex flex-col lg:items-start pr-0 lg:pr-10">
                            <div className="text-start mb-3">
                                <span className="font-semibold text-blue-excendo py-1 px-4 rounded-full shadow-md bg-gray-50">Auktoriserade Redovisningskonsult</span>
                            </div>
                            <h1 className="text-left text-xl md:text-2xl lg:text-4xl font-bold text-black-excendo mt-10 filter drop-shadow-md">Om den Auktoriserade Redovisningskonsulten</h1>
                            <p className="text-gray-excendo xl:text-lg mt-4 lg:mt-8 xl:mt-10 text-left sm:w-3/4 lg:w-full">
                                Den Auktoriserade Redovisningskonsulten fungerar i många fall som det mindre företagets ekonomiavdelning, och utför tjänster inom såväl redovisning och beskattning som affärsutveckling och rådgivning. Det är även den Auktoriserade Redovisningskonsulten som upprättar den löpande redovisningen, deklarationer, bokslut och årsredovisningar, och deltar i diskussioner om företagets ekonomi och budgetarbete.
                            </p>
                            <p className="text-gray-excendo xl:text-lg mt-4 lg:mt-8 xl:mt-10 text-left sm:w-3/4 lg:w-full">
                                Genom att arbeta enligt Reko uppstår kvaliteten i redovisningen och i den finansiella rapporteringen redan i det löpande arbetet, i stället för i efterhand via rättelser. Den Auktoriserade Redovisningskonsulten är också proaktiv och genom sin löpande kundkontakt väl insatt i företagets verksamhet. En Auktoriserad Redovisningskonsult en därför viktig rådgivare vid företagets diskussioner och beslut om utveckling av verksamheten.
                            </p>
                            <h3 className="text-left text-xl md:text-2xl lg:text-4xl font-bold text-black-excendo mt-10 filter drop-shadow-md">Auktorisation - en offentlig kvalitetsstämpel för redovisningskonsulter</h3>
                            <p className="text-gray-excendo xl:text-lg mt-4 lg:mt-8 xl:mt-10 text-left sm:w-3/4 lg:w-full">
                                Yrkesrollen som redovisningskonsult har kvalitetssäkrats genom införande av en auktorisation och utvecklingen av Reko. Under 2006 införde Sveriges Redovisningskonsulters Förbund SRF, en auktorisation med kvalitetskontroll för redovisningskonsulter. 2009 inledde SRF och FAR ett samarbete för att försäkra sig om enhetliga villkor för hela branschen.
                            </p>
                            <p className="text-gray-excendo xl:text-lg mt-4 lg:mt-8 xl:mt-10 text-left sm:w-3/4 lg:w-full">
                                Certifikat för Auktorisation erhålls för en period av 3 år i taget genom att antas som medlem i FAR eller Sveriges Redovisningskonsulters Förbund, SRF. För att antas krävs att kraven uppfylls, samt att medlemmarna regelbundet genomgår en kvalitetskontroll som ska säkerställa hög arbetskvalitet på de erbjudna tjänsterna.
                            </p>
                            <h3 className="text-left text-xl md:text-2xl lg:text-4xl font-bold text-black-excendo mt-10 filter drop-shadow-md">Höga krav för auktorisation</h3>
                            <h4 className="text-left xl:text-lg lg:mt-8 xl:mt-10  sm:w-3/4 lg:w-full font-bold text-black-excendo mt-10 filter drop-shadow-md">För att erhålla medlemskap som ger auktorisation måste redovisningskonsulten uppfylla följande krav:</h4>
                            <ul className="lg:pl-14 mb-5 md:pl-28 ml-4">
                                <li className="list-disc mt-4">
                                    <p className="text-gray-excendo xl:text-lg text-left sm:w-3/4 lg:w-full">
                                        utbildning inom redovisning, skatt och juridik på högskolenivå
                                    </p>
                                </li>
                                <li className="list-disc">
                                    <p className="text-gray-excendo xl:text-lg text-left sm:w-3/4 lg:w-full">
                                        dokumenterad praktik inom redovisning, skatt och rådgivning i 3-5 år
                                    </p>
                                </li>
                                <li className="list-disc">
                                    <p className="text-gray-excendo xl:text-lg text-left sm:w-3/4 lg:w-full">
                                        examensprov för auktorisation
                                    </p>
                                </li>
                                <li className="list-disc">
                                    <p className="text-gray-excendo xl:text-lg text-left sm:w-3/4 lg:w-full">
                                        genomgå en auktorisationsdag med information om bl a Reko
                                    </p>
                                </li>
                            </ul>
                            <h4 className="xl:text-lg lg:mt-8 xl:mt-10 text-left sm:w-3/4 lg:w-full font-bold text-black-excendo mt-10 filter drop-shadow-md">För att sedan behålla sitt medlemskap och sin auktorisation måste man som medlem:</h4>
                            <ul className="lg:pl-14 mb-5 md:pl-28 ml-4">
                                <li className="list-disc mt-4">
                                    <p className="text-gray-excendo xl:text-lg text-left sm:w-3/4 lg:w-full">
                                        genomgå löpande aktualitetsutbildning
                                    </p>
                                </li>
                                <li className="list-disc">
                                    <p className="text-gray-excendo xl:text-lg text-left sm:w-3/4 lg:w-full">
                                        genomgå föreskriven kvalitetskontroll
                                    </p>
                                </li>
                                <li className="list-disc">
                                    <p className="text-gray-excendo xl:text-lg text-left sm:w-3/4 lg:w-full">
                                        inneha godkänd företagsförsäkring mot ansvars- och förmögenhetsskada
                                    </p>
                                </li>
                                <li className="list-disc">
                                    <p className="text-gray-excendo xl:text-lg text-left sm:w-3/4 lg:w-full">
                                        följa etiska regler
                                    </p>
                                </li>
                            </ul>
                            <p className="text-gray-excendo xl:text-lg text-left sm:w-3/4 lg:w-full">
                                Vart tredje år måste certifikatet som Auktoriserad Redovisningskonsult förnyas, och då måste kraven på löpande aktualitetsutbildning ha uppfyllts.
                            </p>
                            <p className="text-gray-excendo xl:text-lg text-left sm:w-3/4 lg:w-full">
                                För att säkerställa att arbetet sker i enlighet med Svensk standard för redovisningstjänster, Reko, genomgår samtliga Auktoriserade Redovisningskonsulter en kvalitetskontroll minst vart sjätte år. Däremellan sker uppföljning genom en särskild kvalitetsdeklaration.
                            </p>
                        </div>
                    </div>
                </div>
                {/*-------------- text section - END ---------------*/}
            </section>

            <ContactSection />
            <Footer />
        </Layout>
    );
}

export default AuktoriseradeRedovisningskonsult;